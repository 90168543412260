import { connect } from 'react-redux';
import Maps from '../../../components/case/subMenus/Maps';
import { setShadeMaps, setTranslucency, setBlackAndWhite, setBackground, setMapType, setAllMaps, setShadeLimits, setColorMapSensitivity, setModifierSensitivity } from '../../../actions/workArea.actions';
import { getIsAllMapsActive, getIsAllMapsAllowed, getIsCurrentImageMap, getIsDoubleMapActive, getIsDoubleMapAllowed, getIsModifierActive, getIsShadeMapActive, getMapsValues } from '../../../selectors/workArea.selector';
import { generateShadeMap, changeCurrentModifier, selectedPhotoType } from '../../../actions/case.actions';
import { getCurrentModifier, getCurrentModifiersSelectList } from '../../../selectors/case.selector';

const mapStateToProps = state => ({
    ...getMapsValues(state),
    
    modifiersList: getCurrentModifiersSelectList(state),
    currentModifier: getCurrentModifier(state),
    isShadeMapsActive: getIsShadeMapActive(state),
    isModifierActive: getIsModifierActive(state),
    isAllMapsActive: getIsAllMapsActive(state),
    isAllMapsAllowed: getIsAllMapsAllowed(state),
    isCurrentImageMap: getIsCurrentImageMap(state),
    isDoubleMapsActive: getIsDoubleMapActive(state),
    isDoubleMapAllowed: getIsDoubleMapAllowed(state)
});
const mapDispatchToProps = dispatch => ({
    setShadeMaps: (value) => {dispatch(setShadeMaps(value))},
    setTranslucency: (value) => {dispatch(setTranslucency(value))},
    setBlackAndWhite: (value) => {dispatch(setBlackAndWhite(value))},
    setBackground: (id) => {dispatch(setBackground(id))},
    setMapType: (id) => {dispatch(setMapType(id))},
    setAllMaps: (value) => {dispatch(setAllMaps(value))},
    generateShadeMap: (newProps) => {dispatch(generateShadeMap(newProps))},
    setShadeLimits: (value) =>  dispatch(setShadeLimits(value)),
    setColorMapSensitivity: (value) =>  dispatch(setColorMapSensitivity(value)),
    setModifierSensitivity: (value) =>  dispatch(setModifierSensitivity(value)),
    changeCurrentModifier: (value) => dispatch(changeCurrentModifier(value)),
    selectedPhotoType: (value) => dispatch(selectedPhotoType(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Maps);
