import React from "react";
import PropTypes from "prop-types";
//import useImage from "use-image";
import { Image as ImageComponent } from 'react-konva';
import Konva from 'konva';
import { modifyBrightness, modifyContrast } from "../../../helpers/photo";

export default class Image extends React.PureComponent {
    
    tid = null;

    componentDidUpdate = (prevProps) => {
        let {image} = this.props;
        if (image) {
            let changed = true;//image != prevProps.image || brightness != prevProps.brightness || contrast != prevProps.contrast;
            
            if (this.imageRef && changed) {
                //console.log('image update')
                if (this.imageRef.isCached()) {
                    this.clearCanvases();
                    this.imageRef.clearCache();
                }
                
                //this.imageRef.getCanvas().getContext().drawImage(image, 0, 0)
                this.imageRef.cache();
                this.imageRef.getLayer().batchDraw();
            }
        }
    }

    componentDidMount = () => {
        let {image} = this.props;
        //console.log('image mount')
        if (image) {
            if (this.imageRef) {
                //console.log('image mount draw')
                /*const c = this.imageRef.getCanvas();
                c.width = image.width;
                c.height = image.height;
                c.getContext().drawImage(image, 0, 0)*/
                this.imageRef.cache();
                this.imageRef.getLayer().batchDraw();
            }
        }
    }

    componentWillUnmount() {
        if (this.imageRef) {
            //console.log('image unmount')
            this.clearCanvases();
            this.imageRef.clearCache();
            
            this.imageRef.destroy();
        }
        
    }

    clearCanvases = () => {
        //const cn = this.imageRef?.getCanvas()
        //if (cn) {
        //    cn.width = 0;
        //    cn.height = 0;
            //cn = null
        //}
        const cnv = this.imageRef?._cache?.get("canvas");
        if (cnv) {
            if (cnv.scene?._canvas) {
                cnv.scene._canvas.width = 0;
                cnv.scene._canvas.height = 0;
            }
            if (cnv.hit?._canvas) {
                cnv.hit._canvas.width = 0;
                cnv.hit._canvas.height = 0;
            }
            if (cnv.filter?._canvas) {
                cnv.filter._canvas.width = 0;
                cnv.filter._canvas.height = 0;
            }
        }
    }

    render() {
        let {image, x, y, width, height, brightness, contrast, isGrayScale, draggable, rotation, scaleX, scaleY, dragBoundFunc, onDragMove, onDragEnd, onTransform, onTransformEnd} = this.props;

        /*if (isNaN(width) || !image) {
            // TODO draw a loader here
            return <Text text={'Loading image...'} fontSize={20} fill={'#ffffff'}/>;
        }*/
        
        
    
        // available filters https://konvajs.org/api/Konva.Filters.htm
        const filters = [
            Konva.Filters.Brighten,
            Konva.Filters.Contrast,  
        ];
        
        if (isGrayScale) {
            filters.push(Konva.Filters.Grayscale);
        }
    
        return (image) ? 
            <ImageComponent
                ref={node => {
                    this.imageRef = node;
                }}
                x={x + width/2}
                y={y + height/2}
                image={image}
                width={width}
                filters={filters}
                brightness={modifyBrightness(brightness)}
                contrast={modifyContrast(contrast)}
                height={height}
                draggable={draggable}
                rotation={rotation}
                offset={{x : width/2, y : height/2}}
                scaleX={scaleX}
                scaleY={scaleY}
                dragBoundFunc={dragBoundFunc}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onTransform={onTransform}
                onTransformEnd={onTransformEnd}
            />
            : null;
        ;
    }
}

Image.propTypes = {
    image: PropTypes.object,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};
