import CaseActionTypes from './case.types';

export const loadCase = caseId => ({
    type: CaseActionTypes.LOAD_CASE,
    caseId: caseId
});
export const caseLoaded = currentCase => ({
    type: CaseActionTypes.LOAD_CASE_SUCCESS,
    currentCase,
});

export const caseLoadFailure = error => ({
    type: CaseActionTypes.LOAD_CASE_FAILED,
    error
});

export const loadCases = (limit, casesFilter, lastCaseId, lastDate) => ({
    type: CaseActionTypes.LOAD_CASES,
    limit,
    casesFilter,
    lastCaseId,
    lastDate,
});
export const casesLoaded = cases => ({
    type: CaseActionTypes.LOAD_CASES_SUCCESS,
    cases,
});
export const additionalCasesLoaded = cases => ({
    type: CaseActionTypes.ADD_LOAD_CASES_SUCCESS,
    cases,
});

export const casesFailed = error => ({
    type: CaseActionTypes.LOAD_CASES_FAILED,
    payload : error
});

export const loadAvailableShadeSystems = () => ({
    type: CaseActionTypes.LOAD_AVAILABLE_SHADE_SYSTEMS,
});

export const availableShadeSystemsSuccess = shadeSystems => ({
    type: CaseActionTypes.LOAD_AVAILABLE_SHADE_SYSTEMS_SUCCESS,
    shadeSystems
});

export const availableShadeSystemsFailed = error => ({
    type: CaseActionTypes.LOAD_AVAILABLE_SHADE_SYSTEMS_FAILED,
    payload : error
});

export const loadLastUsedSettings = () => ({
    type: CaseActionTypes.LOAD_LAST_USED_SETTINGS,
});

export const lastUsedSettingsLoaded = settings => ({
    type: CaseActionTypes.LOAD_LAST_USED_SETTINGS_SUCCESS,
    settings
});

export const lastUsedSettingsFailed = error => ({
    type: CaseActionTypes.LOAD_LAST_USED_SETTINGS_FAILED,
    payload : error
});

export const getShadeGuide = shadeGuideId => ({
    type: CaseActionTypes.GET_SHADE_GUIDE,
    shadeGuideId
});

export const shadeGuideLoaded = (shadeGuide) => ({
    type: CaseActionTypes.SHADE_GUIDE_LOADED,
    shadeGuide,
});
export const shadeGuideByKeyLoaded = (shadeGuides, storeKey) => ({
    type: CaseActionTypes.SHADE_GUIDE_BY_KEY_LOADED,
    shadeGuides,
    storeKey,
});

export const shadeGuideFailed = error => ({
    type: CaseActionTypes.SHADE_GUIDE_FAILED,
    error,
});

export const getShadeGuides = () => ({
    type: CaseActionTypes.GET_SHADE_GUIDES
});
export const getShadeTabs = (guideId, storeKey) => ({
    type: CaseActionTypes.GET_SHADE_TABS,
    guideId,
    storeKey,
});
export const shadeTabsIsLoading = (isLoading, storeKey) => ({
    type: CaseActionTypes.SHADE_TABS_IS_LOADING,
    isLoading,
    storeKey,
});

export const shadeGuidesLoaded = (shadeGuides) => ({
    type: CaseActionTypes.SHADE_GUIDES_LOADED,
    shadeGuides,
});

export const shadeGuidesFailed = error => ({
    type: CaseActionTypes.SHADE_GUIDES_FAILED,
    error,
});

export const standardModifiersLoaded = standardModifyers => ({
    type: CaseActionTypes.STANDARD_MODIFIERS_LOADED,
    standardModifyers,
});


export const leftMenuAction = actionType => ({
    type: actionType,
});
export const setActivePhoto = photoId => ({
    type: CaseActionTypes.SET_ACTIVE_PHOTO,
    photoId,
});

export const uploadPhoto = (file, fileContent, caseId) => ({
    type: CaseActionTypes.UPLOAD_PHOTO,
    file,
    fileContent,
    caseId,
});
export const uploadPhotoSuccess = data => ({
    type: CaseActionTypes.UPLOAD_PHOTO_SUCCESS,
    data
});
export const uploadPhotoError = message => ({
    type: CaseActionTypes.UPLOAD_PHOTO_ERROR,
    message
});
export const hideUploadPhotoError = message => ({
    type: CaseActionTypes.HIDE_UPLOAD_PHOTO_ERROR,
    message
});
export const addLoadedPhoto = photo => ({
    type: CaseActionTypes.ADD_LOADED_PHOTO,
    photo
});

export const setShadeTabs = selectedTabsIds => ({
    type: CaseActionTypes.SET_SHADE_TABS,
    selectedTabsIds,
});

export const photoOriginalLoaded = photo => ({
    type: CaseActionTypes.PHOTO_ORIGINAL_LOADED,
    photo
}) ;

export const selectedPhotoType = photoType => ({
    type: CaseActionTypes.SELECTED_PHOTO_TYPE,
    photoType
});

export const setShadeTabsSuccess = selectedTabsIds => ({
    type: CaseActionTypes.SET_SHADE_TABS_SUCCESS,
    selectedTabsIds
});

export const photoCorrectedLoaded = (image, imageView, photoId) => ({
    type: CaseActionTypes.PHOTO_CORRECTED_LOADED,
    image,
    imageView,
    photoId
});

export const deleteCase = (caseId) => ({
    type: CaseActionTypes.DELETE_CASE,
    caseId
});
export const caseDeleted = caseId => ({
    type: CaseActionTypes.CASE_DELETED,
    caseId
});


export const createCase = (data, files) => ({
    type: CaseActionTypes.CREATE_CASE,
    data,
    files,
});
export const createCasePending = () => ({
    type: CaseActionTypes.CREATE_CASE_PENDING,
});
export const createCaseSuccess = (caseId) => ({
    type: CaseActionTypes.CREATE_CASE_SUCCESS,
    caseId,
});
export const createCaseError = (error) => ({
    type: CaseActionTypes.CREATE_CASE_ERROR,
    error
});

export const getNewCaseNum = () => ({
    type: CaseActionTypes.GET_NEW_CASE_NUM,
});
export const getNewCaseNumSuccess = (caseNum) => ({
    type: CaseActionTypes.GET_NEW_CASE_NUM_SUCCESS,
    caseNum,
});

export const generateShadeMap = (newProps) => ({
        type: CaseActionTypes.GENERATE_SHADE_MAP,
        newProps,
    });

export const shadeMapGenerated = (image, mask, photoId, params, imageType, isRegion) => ({
    type: CaseActionTypes.SHADE_MAP_GENERATED,
    image,
    mask,
    photoId,
    params,
    imageType,
    isRegion
});
export const deletePhoto = (photoId) => ({
    type: CaseActionTypes.DELETE_PHOTO,
    photoId,
});

export const getConnectedSpecialists = () => ({
    type: CaseActionTypes.GET_CONNECTED_SPECIALISTS,
});
export const getConnectedSpecialistsSuccess = (specialists) => ({
    type: CaseActionTypes.GET_CONNECTED_SPECIALISTS_SUCCESS,
    specialists
});
export const getLabs = () => ({
    type: CaseActionTypes.GET_LABS,
});
export const getLabsSuccess = (labs) => ({
    type: CaseActionTypes.GET_LABS_SUCCESS,
    labs
});

export const setCaseLab = (caseId, labId) => ({
    type: CaseActionTypes.SET_CASE_LAB,
    caseId,
    labId,
});
export const setCaseSpecialist = (caseId, specialistId) => ({
    type: CaseActionTypes.SET_CASE_SPECIALIST,
    caseId,
    specialistId,
});
export const setCaseLabError = (error, caseId, labId) => ({
    type: CaseActionTypes.SET_CASE_LAB_ERROR,
    error,
    caseId,
    labId,
});
export const setCaseLabSuccess = (message, caseId, labId) => ({
    type: CaseActionTypes.SET_CASE_LAB_SUCCESS,
    caseId,
    message,
    labId,
});
export const errorMessageShown = () => ({
    type: CaseActionTypes.ERROR_MESSAGE_SHOWN,
});
export const setCaseSpecialistError = (error, caseId, specId) => ({
    type: CaseActionTypes.SET_CASE_SPECIALIST_ERROR,
    error,
    caseId,
    specId,
});
export const setCaseSpecialistSuccess = (message, caseId, specId) => ({
    type: CaseActionTypes.SET_CASE_SPECIALIST_SUCCESS,
    caseId,
    message,
    specId,
});

export const addShadeSelected = (color, refColor, studioColor, coordinate) => ({
    type: CaseActionTypes.ADD_SHADE_SELECTED,
    color,
    refColor,
    studioColor,
    coordinate,
});

export const setLeftRightShade = (isLeft, shadeName) => ({
    type: CaseActionTypes.SET_LEFT_RIGHT_SHADE,
    isLeft,
    shadeName
});

export const runColorCorrect = () => ({
    type: CaseActionTypes.RUN_COLOR_CORRECT,
});

export const restartOriginalImage = () => ({
    type: CaseActionTypes.RESTART_ORIGINAL_IMAGE,
});
export const loadCaseMessages = data => ({
    type: CaseActionTypes.LOAD_CASE_MESSAGES,
    payload: data,
});
export const loadCaseMessagesSuccess = data => ({
    type: CaseActionTypes.CASE_MESSAGES_LOADED,
    payload: data,
});
export const loadAllCasePhoto = data => ({
    type: CaseActionTypes.LOAD_ALL_CASE_PHOTO,
    payload: data,
});
export const loadAllCasePhotoSuccess = data => ({
    type: CaseActionTypes.ALL_CASE_PHOTO_LOADED,
    payload: data,
});
export const setActiveCaseId = caseId => ({
    type: CaseActionTypes.SET_ACTIVE_CASE_ID,
    payload: caseId,
});
export const setRoles = roles => ({
    type: CaseActionTypes.SET_ROLES,
    payload: roles,
});
export const saveCaseMessage = data => ({
    type: CaseActionTypes.SAVE_CASE_MESSAGE,
    payload: data,
});
export const saveCaseAttachment = data => ({
    type: CaseActionTypes.SAVE_MESSAGE_ATTACHMENT,
    payload: data,
});
export const loadCaseMessagePhoto = data => ({
    type: CaseActionTypes.LOAD_CASE_MESSAGE_PHOTO,
    payload: data,
});
export const caseMessagePhotoLoaded = data => ({
    type: CaseActionTypes.CASE_MESSAGE_PHOTO_LOADED,
    payload: data,
});
export const emptyCaseMessagePhotos = () => ({
    type: CaseActionTypes.EMPTY_CASE_MESSAGE_PHOTOS
});
export const caseMessagesSaveFinished = () => ({
    type: CaseActionTypes.CASE_MESSAGES_SAVE_FINISHED
});
export const caseMessagePhotoSaveFinished = payload => ({
    type: CaseActionTypes.CASE_MESSAGE_PHOTO_SAVE_FINISHED,
    payload
});
export const setPhotosToSaveCount = count => ({
    type: CaseActionTypes.SET_PHOTOS_TO_SAVE_COUNT,
    payload: count
});
export const resetCaseMessagesSaved = () => ({
    type: CaseActionTypes.RESET_CASE_MESSAGES_SAVED
});
export const resetCaseMessagePhotosSaved = () => ({
    type: CaseActionTypes.RESET_CASE_MESSAGE_PHOTOS_SAVED
});
export const resetCasePhotoLoaded = () => ({
    type: CaseActionTypes.RESET_CASE_MESSAGE_PHOTOS_LOADED
});

export const deletePhotoSuccess = (photoId) => ({
    type: CaseActionTypes.DELETE_PHOTO_SUCCESS,
    photoId,
});

export const autoCompleteSend = (username, field) => ({
    type: CaseActionTypes.AUTOCOMPLETE_SEND,
    username,
    field,
});
export const autoCompleteResult = (results) => ({
    type: CaseActionTypes.AUTOCOMPLETE_RESULT,
    results,
});

export const changeCurrentModifier = (value) => ({
    type: CaseActionTypes.CHANGE_CURRENT_MODIFIER,
    value
});

export const tripleImageGenerated = (result, photoId, params, imageType) => ({
    type: CaseActionTypes.TRIPLE_IMAGES_GENERATED,
    result,
    photoId,
    params,
    imageType
});

export const doubleImageGenerated = (result, photoId, params, imageType) => ({
    type: CaseActionTypes.DOUBLE_IMAGE_GENERATED,
    result,
    photoId,
    params,
    imageType
})


export const processRegion = (rect, subrect) => ({
    type: CaseActionTypes.PROCESS_REGION,
    rect,
    subrect
});

export const regionCorrectedGenerated = (image, imageView, photoId) => ({
    type: CaseActionTypes.REGION_CORRECTED_GENERATED,
    image,
    imageView,
    photoId
});

export const standardViewColorsLoaded = (data) => ({
    type: CaseActionTypes.STANDARD_VIEW_COLORS_LOADED,
    data
});

export const getRegionValue = (rect) => ({
    type: CaseActionTypes.GET_REGION_VALUE,
    rect
});

export const photoMetadataUpdated = (photoId, metadata) => ({
    type: CaseActionTypes.PHOTO_METADATA_UPDATED,
    photoId,
    metadata
});

export const photoMetadataLoaded = (metadata) => ({
    type: CaseActionTypes.PHOTO_METADATA_LOADED,
    metadata,
}) ;

export const updateShadeTabCoords = (photoId, coords) => ({
    type: CaseActionTypes.UPDATE_SHADE_TAB_COORDS,
    photoId,
    coords
})

export const updateTextAnnotations = (photoId, payload) => ({
    type: CaseActionTypes.UPDATE_TEXT_ANNOTATIONS,
    photoId,
    payload,
});

export const updateGraphicAnnotations = (photoId, payload) => ({
    type: CaseActionTypes.UPDATE_GRAPHIC_ANNOTATIONS,
    photoId,
    payload,
});



export const addShadeMapLabel = (point) => ({
    type: CaseActionTypes.ADD_SHADEMAP_LABEL,
    point
});

export const addModifierLabel = (point) => ({
    type: CaseActionTypes.ADD_MODIFIER_LABEL,
    point
});

export const addShadeTransLabel = (point) => ({
  type: CaseActionTypes.ADD_SHADE_TRANS_LABEL,
  point
});

export const removeShadeLabel = (index) => ({
    type: CaseActionTypes.REMOVE_SHADE_LABEL,
    index
});

export const updateShadeTransPosition = (payload) => ({
  type: CaseActionTypes.UPDATE_SHADE_TRANS_POSITION,
  payload
})


export const updateShadeLabelsMetadata = () => ({
    type: CaseActionTypes.UPDATE_SHADE_LABELS_METADATA,
});

export const updateShadeMapLabels = (shLabels, mrLabels) => ({
    type: CaseActionTypes.UPDATE_SHADE_MAP_LABELS,
    shLabels,
    mrLabels
});

export const setHideXMarks = (value) => ({
    type: CaseActionTypes.SET_HIDE_X_MARKS,
    value
});

export const setShowShadeTabs = (value) => ({
    type: CaseActionTypes.SET_SHOW_SHADE_TABS,
    value
})

export const setShowTextAnnotations = (payload) => ({
    type: CaseActionTypes.SET_SHOW_TEXT_ANNOTATIONS,
    payload
})

export const setShowGraphicsAnnotations = (payload) => ({
    type: CaseActionTypes.SET_SHOW_GRAPHICS_ANNOTATIONS,
    payload
})


export const setShowCrossHair = (payload) => ({
  type: CaseActionTypes.SET_SHOW_CROSS_HAIR,
  payload
})

export const saveCrossHairPosition = (payload) => ({
  type: CaseActionTypes.SAVE_CROSSHAIR_POSITION,
  payload
})

export const downloadPhotos = (caseId, photosList) => ({
    type: CaseActionTypes.DOWNLOAD_PHOTOS,
    caseId,
    photosList
});

export const markCaseComplete = (caseId) => ({
    type: CaseActionTypes.MARK_CASE_COMPLETE,
    caseId,
});

export const markCaseNew = (caseId) => ({
    type: CaseActionTypes.MARK_CASE_NEW,
    caseId,
});

export const saveImageToServer = (canvas, photoId, photo_type) => ({
    type: CaseActionTypes.SAVE_IMAGE_TO_SERVER,
    canvas,
    photoId,
    photo_type
})

export const caseCompleted = (caseId) => ({
    type: CaseActionTypes.CASE_COMPLETED,
    caseId
})

export const caseRenewed = (caseId) => ({
    type: CaseActionTypes.CASE_RENEWED,
    caseId
})

export const setQcLeftImage = (photo) => ({
    type: CaseActionTypes.SET_LEFT_QC_IMAGE,
    photo
})

export const setQcRightImage = (photo) => ({
    type: CaseActionTypes.SET_RIGHT_QC_IMAGE,
    photo
})

export const setQcMode = (value) => ({
    type: CaseActionTypes.SET_QC_MODE,
    value
})

export const loadQcPhoto = photoId => ({
    type: CaseActionTypes.LOAD_QC_PHOTO,
    photoId,
});

export const updateLeftQcValues = (values) => ({
    type: CaseActionTypes.UPDATE_LEFT_QC_VALUES,
    values
});

export const updateRightQcValues = (values) => ({
    type: CaseActionTypes.UPDATE_RIGHT_QC_VALUES,
    values
});

export const updateQcValues = (values) => ({
    type: CaseActionTypes.UPDATE_QC_VALUES,
    values
});

export const changeWalkthroughSettings = (value) => ({
    type: CaseActionTypes.UPDATE_SHOW_WALKTHROUGH,
    value
});

export const saveRotatedPhoto = (photoId, rotation) => ({
    type: CaseActionTypes.SAVE_ROTATED_PHOTO,
    photoId,
    rotation
});

export const saveFlippedPhoto = (photoId, flipOrientation) => ({
    type: CaseActionTypes.SAVE_FLIPPED_PHOTO,
    photoId,
    flipOrientation
});

export const undoSavedPhoto = (photoId) => ({
    type: CaseActionTypes.UNDO_SAVED_PHOTO,
    photoId
});

export const undoCompleted = (photoId) => ({
    type: CaseActionTypes.UNDO_PHOTO_COMPLETED,
    photoId
});

export const redoSavedPhoto = (photoId) => ({
    type: CaseActionTypes.REDO_SAVED_PHOTO,
    photoId
});

export const redoCompleted = (photoId) => ({
    type: CaseActionTypes.REDO_PHOTO_COMPLETED,
    photoId
})
  
export const checkAllMessagesRead = (caseId) => ({
    type: CaseActionTypes.CHECK_ALL_MESSAGES_READ,
    caseId
})

export const messagesReadAll = (allSentRead, hasUnreadReceived) => ({
    type: CaseActionTypes.MESSAGES_READ_ALL,
    allSentRead,
    hasUnreadReceived
})

export const notifyLabAboutPhotos = (caseId) => ({
    type: CaseActionTypes.NOTIFY_LAB_ABOUT_PHOTOS,
    caseId
})

export const getMagicTouchCustomersList = (token, labid) => ({
    type: CaseActionTypes.GET_MAGICTOUCH_CUSTOMERS_LIST,
    token,
    labid
})

export const magicTouchCustomersListLoaded = (payload) => ({
    type: CaseActionTypes.MAGICTOUCH_CUSTOMERS_LIST_LOADED,
    payload
})

export const getMagicTouchPracticeDoctorsList = (token, customerid) => ({
    type: CaseActionTypes.GET_MAGICTOUCH_PRACTICE_DOCTORS_LIST,
    token,
    customerid
})

export const magicTouchPracticeDoctorsListLoaded = (payload) => ({
    type: CaseActionTypes.MAGICTOUCH_PRACTICE_DOCTORS_LIST_LOADED,
    payload
})

export const getMagicTouchProductsList = (token, labid) => ({
    type: CaseActionTypes.MAGICTOUCH_GET_PRODUCTS_LIST,
    token,
    labid
})


export const magicTouchProductsListLoaded = (payload) => ({
    type: CaseActionTypes.MAGICTOUCH_PRODUCTS_LIST_LOADED,
    payload
})

export const updateShadeTabSettings = (photoId, settings) => ({
    type: CaseActionTypes.UPDATE_SHADE_TAB_SETTINGS,
    photoId,
    settings
})

export const updateAlternativeId = (payload) => ({
    type: CaseActionTypes.UPDATE_ALTERNATIVE_ID,
    payload
  })
  
export const updateCaseDetails = (payload) => ({
  type: CaseActionTypes.UPDATE_CASE_DETAILS,
  payload
})



