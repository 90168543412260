import workAreaTypes from "./workArea.types";

/**/
export const showSubMenu = menu => ({
    type: workAreaTypes.SHOW_SUB_MENU,
    menu
});
export const hideSubMenu = () => ({
    type: workAreaTypes.HIDE_SUB_MENU
});

/**/
export const selectShadeGuide = (guideId) => ({
    type: workAreaTypes.SELECT_SHADE_GUIDE,
    guideId
});
export const expandShadeGuide = () => ({
    type: workAreaTypes.EXPAND_SHADE_GUIDE,
});

export const unexpandShadeGuide = () => ({
    type: workAreaTypes.UNEXPAND_SHADE_GUIDE,
});

export const updateShadeGuides = (shadeGuides) => ({
    type: workAreaTypes.UPDATE_SHADE_GUIDE,
    shadeGuides
});
/**/
export const changeImageType = (name) => ({
    type: workAreaTypes.CHANGE_IMAGE_TYPE,
    name
});
export const choseRegion = (name) => ({
    type: workAreaTypes.CHOSE_REGION,
    name
});
export const changeCorrectedImageType = (name) => ({
    type: workAreaTypes.CHANGE_CORRECTED_IMAGE_TYPE,
    name
});

/**/
export const rotateImage = (degrees) => ({
    type: workAreaTypes.ROTATE_IMAGE,
    degrees
});
export const setGrayScale = (value) => ({
    type: workAreaTypes.SET_GRAY_SCALE,
    value
});
export const setShadeLimits = (value) => ({
    type: workAreaTypes.SET_SHADE_LIMITS,
    value
});
export const setBrightness = (value) => ({
    type: workAreaTypes.SET_BRIGHTNESS,
    value
});
export const setContrast = (value) => ({
    type: workAreaTypes.SET_CONTRAST,
    value
});
export const setColorMapSensitivity = (value) => ({
    type: workAreaTypes.SET_COLOR_MAP_SENSITIVITY,
    value
});
export const setModifierSensitivity = (value) => ({
    type: workAreaTypes.SET_MODIFIER_SENSITIVITY,
    value
});

/**/
export const setShadeMaps = (value) => ({
    type: workAreaTypes.SET_SHADE_MAPS,
    value
});
export const setTranslucency = (value) => ({
    type: workAreaTypes.SET_TRANSLUENCY,
    value
});
export const setBlackAndWhite = (value) => ({
    type: workAreaTypes.SET_BLACK_AND_WHITE,
    value
});
export const setGrayscaleMap = (value) => ({
    type: workAreaTypes.SET_GRAY_SCALE_MAP,
    value
});
export const setAllMaps = (value) => ({
    type: workAreaTypes.SET_ALL_MAPS,
    value
});
export const setBackground = (name) => ({
    type: workAreaTypes.SET_BACKGROUND,
    name
});

export const setMapType = (name) => ({
    type: workAreaTypes.SET_MAP_TYPE,
    name
});

/**/
export const setTheme = (name) => ({
    type: workAreaTypes.SET_THEME,
    name
});
export const setProcessPhotoWalkthrough = (name) => ({
    type: workAreaTypes.SET_PROCESS_PHOTO_WALKTHROUGH,
    name
});
export const setShadeReferenceAnalysis = (name) => ({
    type: workAreaTypes.SET_SHADE_REFERENCE_ANALYSIS,
    name
});

export const toggleWalkthroughExpand = isExpanded => ({
    type: workAreaTypes.TOGGLE_WALKTHROUGH_EXPAND,
    isExpanded,
});
export const closeWalkthrough = (notShow) => ({
    type: workAreaTypes.CLOSE_WALKTHROUGH,
    notShow
});
export const setPhotoContainerRef = ref => ({
    type: workAreaTypes.SET_PHOTO_CONTAINER_REF,
    ref
});

export const toggleCrop = value => ({
    type: workAreaTypes.TOGGLE_CROP,
    value,
});
export const cropImage = (photoId, caseId, details) => ({
    type: workAreaTypes.CROP_IMAGE,
    photoId, caseId, details,
});
export const cropImageSuccess = response => ({
    type: workAreaTypes.CROP_IMAGE_SUCCESS,
    response,
});
export const grayscaleImage = (photoId) => ({
  type: workAreaTypes.GRAYSCALE_IMAGE,
  photoId
})

export const grayscaleImageSuccess = (response) => ({
  type: workAreaTypes.GRAYSCALE_SUCCESS,
  response
});

export const photoProcessingError = error => ({
    type: workAreaTypes.PHOTO_PROCESSING_ERROR,
    error,
});
export const hidePhotoProcessingError = () => ({
    type: workAreaTypes.HIDE_PHOTO_PROCESSING_ERROR,
});

export const regionColorValuesReceived = (result) => ({
    type: workAreaTypes.REGION_COLOR_VALUES_RECEIVED,
    result
});

