const prefix = 'CASE_';

const CaseActionTypes = {
    LOAD_CASE: 'LOAD_CASE',
    LOAD_CASE_SUCCESS: 'LOAD_CASE_SUCCESS',
    LOAD_CASE_FAILED: 'LOAD_CASE_FAILED',

    LOAD_CASES: 'LOAD_CASES',
    LOAD_CASES_SUCCESS: 'LOAD_CASES_SUCCESS',
    ADD_LOAD_CASES_SUCCESS: 'ADD_LOAD_CASES_SUCCESS',
    LOAD_CASES_FAILED: 'LOAD_CASES_FAILED',

    LOAD_LAST_USED_SETTINGS: 'LOAD_LAST_USED_SETTINGS',
    LOAD_LAST_USED_SETTINGS_SUCCESS: 'LOAD_LAST_USED_SETTINGS_SUCCESS',
    LOAD_LAST_USED_SETTINGS_FAILED: 'LOAD_LAST_USED_SETTINGS_FAILED',

    LOAD_USER_SHADE_SYS: 'LOAD_USER_SHADE_SYS',
    LOAD_USER_SHADE_SYS_SUCCESS: 'LOAD_USER_SHADE_SYS',
    LOAD_USER_SHADE_SYS_FAILED: 'LOAD_USER_SHADE_SYS',

    LOAD_AVAILABLE_SHADE_SYSTEMS: 'LOAD_AVAILABLE_SHADE_SYSTEMS',
    LOAD_AVAILABLE_SHADE_SYSTEMS_SUCCESS :'LOAD_AVAILABLE_SHADE_SYSTEMS_SUCCESS',
    LOAD_AVAILABLE_SHADE_SYSTEMS_FAILED: 'LOAD_AVAILABLE_SHADE_SYSTEMS_FAILED',

    LOAD_STANDARD_MODIFIERS: 'LOAD_STANDARD_MODIFIERS',
    STANDARD_MODIFIERS_LOADED: 'STANDARD_MODIFIERS_LOADED',
    LOAD_STANDARD_MODIFIERS_FAILED: 'LOAD_STANDARD_MODIFIERS_FAILED',

    GET_SHADE_GUIDE: 'GET_SHADE_GUIDE',
    SHADE_GUIDE_LOADED: 'SHADE_GUIDE_LOADED',
    SHADE_GUIDE_BY_KEY_LOADED: 'SHADE_GUIDE_BY_KEY_LOADED',
    SHADE_GUIDE_FAILED: 'SHADE_GUIDE_FAILED',

    GET_SHADE_GUIDES: 'GET_SHADE_GUIDES',
    SHADE_GUIDES_LOADED: 'SHADE_GUIDES_LOADED',
    SHADE_GUIDES_FAILED: 'SHADE_GUIDES_FAILED',

    SHADE_TABS_IS_LOADING: 'SHADE_TABS_IS_LOADING',
    GET_SHADE_TABS: 'GET_SHADE_TABS',
    SHADE_TABS_LOADED: 'SHADE_TABS_LOADED',
    SHADE_TABS_FAILED: 'SHADE_TABS_FAILED',

    LOAD_GALLERY_PHOTOS : 'LOAD_GALLERY_PHOTOS',
    LOAD_GALLERY_PHOTOS_SUCCESS : 'LOAD_GALLERY_PHOTOS_SUCCESS',
    LOAD_GALLERY_PHOTOS_FAILED : 'LOAD_GALLERY_PHOTOS_FAILED',

    PHOTOS:`${prefix}PHOTOS`,
    PROCESS:`${prefix}PROCESS`,
    EDIT:`${prefix}EDIT`,
    MAPS:`${prefix}MAPS`,
    PREFERENCES:`${prefix}PREFERENCES`,
    CASE_DETAILS:`${prefix}CASE_DETAILS`,
    VIEW_CASES:`${prefix}VIEW_CASES`,
    SUPPORT:`${prefix}SUPPORT`,

    SET_ACTIVE_PHOTO: 'SET_ACTIVE_PHOTO',
    UPLOAD_PHOTO: 'UPLOAD_PHOTO',
    UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
    UPLOAD_PHOTO_ERROR: 'UPLOAD_PHOTO_ERROR',
    HIDE_UPLOAD_PHOTO_ERROR: 'HIDE_UPLOAD_PHOTO_ERROR',

    SET_SHADE_TABS: 'SET_SHADE_TABS',

    PHOTO_ORIGINAL_LOADED: 'PHOTO_ORIGINAL_LOADED',
    ADD_LOADED_PHOTO: 'ADD_LOADED_PHOTO',
    PHOTO_CORRECTED_LOADED: 'PHOTO_CORRECTED_LOADED',
    SELECTED_PHOTO_TYPE: 'SELECTED_PHOTO_TYPE',

    SET_SHADE_TABS_SUCCESS: 'SET_SHADE_TABS_SUCCESS',
    DELETE_CASE: 'DELETE_CASE',
    CASE_DELETED: 'CASE_DELETED',

    CREATE_CASE: 'CREATE_CASE',
    CREATE_CASE_PENDING: 'CREATE_CASE_PENDING',
    CREATE_CASE_ERROR: 'CREATE_CASE_ERROR',
    CREATE_CASE_SUCCESS: 'CREATE_CASE_SUCCESS',

    GET_NEW_CASE_NUM: 'GET_NEW_CASE_NUM',
    GET_NEW_CASE_NUM_SUCCESS: 'GET_NEW_CASE_NUM_SUCCESS',

    GENERATE_SHADE_MAP: 'GENERATE_SHADE_MAP',
    SHADE_MAP_GENERATED: 'SHADE_MAP_GENERATED',

    DELETE_PHOTO: 'DELETE_PHOTO',

    GET_LABS: 'GET_LABS',
    GET_LABS_SUCCESS: 'GET_LABS_SUCCESS',
    GET_CONNECTED_SPECIALISTS: 'GET_CONNECTED_SPECIALISTS',
    GET_CONNECTED_SPECIALISTS_SUCCESS: 'GET_CONNECTED_SPECIALISTS_SUCCESS',

    SET_CASE_LAB: 'SET_CASE_LAB',
    SET_CASE_LAB_ERROR: 'SET_CASE_LAB_ERROR',
    SET_CASE_LAB_SUCCESS: 'SET_CASE_LAB_SUCCESS',
    SET_CASE_SPECIALIST: 'SET_CASE_SPECIALIST',
    SET_CASE_SPECIALIST_ERROR: 'SET_CASE_SPECIALIST_ERROR',
    SET_CASE_SPECIALIST_SUCCESS: 'SET_CASE_SPECIALIST_SUCCESS',
    ERROR_MESSAGE_SHOWN: 'ERROR_MESSAGE_SHOWN',

    ADD_SHADE_SELECTED: 'ADD_SHADE_SELECTED',
    SET_LEFT_RIGHT_SHADE: 'SET_LEFT_RIGHT_SHADE',
    RUN_COLOR_CORRECT: 'RUN_COLOR_CORRECT',
    RESTART_ORIGINAL_IMAGE: 'RESTART_ORIGINAL_IMAGE',
    LOAD_CASE_MESSAGES: 'LOAD_CASE_MESSAGES',
    SAVE_CASE_MESSAGE: 'SAVE_CASE_MESSAGE',
    SAVE_MESSAGE_ATTACHMENT: 'SAVE_MESSAGE_ATTACHMENT',
    CASE_MESSAGES_LOADED: 'CASE_MESSAGES_LOADED',
    LOAD_ALL_CASE_PHOTO: 'LOAD_ALL_CASE_PHOTO',
    ALL_CASE_PHOTO_LOADED: 'ALL_CASE_PHOTO_LOADED',
    SET_ACTIVE_CASE_ID: 'SET_ACTIVE_CASE_ID',
    SET_ROLES: 'SET_ROLES',
    LOAD_CASE_MESSAGE_PHOTO: 'LOAD_CASE_MESSAGE_PHOTO',
    CASE_MESSAGE_PHOTO_LOADED: 'CASE_MESSAGE_PHOTO_LOADED',
    EMPTY_CASE_MESSAGE_PHOTOS: 'EMPTY_CASE_MESSAGE_PHOTOS',
    CASE_MESSAGES_SAVE_FINISHED: 'CASE_MESSAGES_SAVE_FINISHED',
    CASE_MESSAGE_PHOTO_SAVE_FINISHED: 'CASE_MESSAGE_PHOTO_SAVE_FINISHED',
    SET_PHOTOS_TO_SAVE_COUNT: 'SET_PHOTOS_TO_SAVE_COUNT',
    RESET_CASE_MESSAGES_SAVED: 'RESET_CASE_MESSAGES_SAVED',
    RESET_CASE_MESSAGE_PHOTOS_SAVED: 'RESET_CASE_MESSAGE_PHOTOS_SAVED',
    RESET_CASE_MESSAGE_PHOTOS_LOADED: 'RESET_CASE_MESSAGE_PHOTOS_LOADED',

    DELETE_PHOTO_SUCCESS: 'DELETE_PHOTO_SUCCESS',

    AUTOCOMPLETE_SEND: 'AUTOCOMPLETE_SEND',
    AUTOCOMPLETE_RESULT: 'AUTOCOMPLETE_RESULT',
    CHANGE_CURRENT_MODIFIER: 'CHANGE_CURRENT_MODIFIER',
    TRIPLE_IMAGES_GENERATED: 'TRIPLE_IMAGES_GENERATED',
    DOUBLE_IMAGE_GENERATED: 'DOUBLE_IMAGE_GENERATED',
    PROCESS_REGION: 'PROCESS_REGION',
    REGION_CORRECTED_GENERATED: 'REGION_CORRECTED_GENERATED',
    STANDARD_VIEW_COLORS_LOADED: 'STANDARD_VIEW_COLORS_LOADED',
    GET_REGION_VALUE: 'GET_REGION_VALUE',
    PHOTO_METADATA_UPDATED: 'PHOTO_METADATA_UPDATED',
    PHOTO_METADATA_LOADED: 'PHOTO_METADATA_LOADED',
    ADD_SHADEMAP_LABEL: 'ADD_SHADEMAP_LABEL',
    ADD_MODIFIER_LABEL: 'ADD_MODIFIER_LABEL',
    ADD_SHADE_TRANS_LABEL: 'ADD_SHADE_TRANS_LABEL',
    UPDATE_SHADE_TRANS_POSITION: 'UPDATE_SHADE_TRANS_POSITION',
    REMOVE_SHADE_LABEL: 'REMOVE_SHADE_LABEL',
    UPDATE_SHADE_LABELS_METADATA: 'UPDATE_SHADE_LABELS_METADATA',
    UPDATE_SHADE_MAP_LABELS: 'UPDATE_SHADE_MAP_LABELS',
    SET_HIDE_X_MARKS: 'SET_HIDE_X_MARKS',
    SET_SHOW_SHADE_TABS: 'SET_SHOW_SHADE_TABS',
    SET_SHOW_TEXT_ANNOTATIONS: 'SET_SHOW_TEXT_ANNOTATIONS',
    SET_SHOW_GRAPHICS_ANNOTATIONS: 'SET_SHOW_GRAPHICS_ANNOTATIONS',
    SET_SHOW_CROSS_HAIR: 'SET_SHOW_CROSS_HAIR',
    DOWNLOAD_PHOTOS: 'DOWNLOAD_PHOTOS',
    MARK_CASE_COMPLETE: 'MARK_CASE_COMPLETE',
    MARK_CASE_NEW: 'MARK_CASE_NEW',
    SAVE_IMAGE_TO_SERVER: 'SAVE_IMAGE_TO_SERVER',
    CASE_COMPLETED: 'CASE_COMPLETED',
    CASE_RENEWED: 'CASE_RENEWED',
    SET_LEFT_QC_IMAGE: 'SET_LEFT_QC_IMAGE',
    SET_RIGHT_QC_IMAGE: 'SET_RIGHT_QC_IMAGE',
    SET_QC_MODE: 'SET_QC_MODE',
    LOAD_QC_PHOTO: 'LOAD_QC_PHOTO',
    UPDATE_LEFT_QC_VALUES: 'UPDATE_LEFT_QC_VALUES',
    UPDATE_RIGHT_QC_VALUES: 'UPDATE_RIGHT_QC_VALUES',
    UPDATE_QC_VALUES: 'UPDATE_QC_VALUES',
    UPDATE_SHOW_WALKTHROUGH: 'UPDATE_SHOW_WALKTHROUGH',
    SAVE_ROTATED_PHOTO: 'SAVE_ROTATED_PHOTO',
    CHECK_ALL_MESSAGES_READ: 'CHECK_ALL_MESSAGES_READ',
    MESSAGES_READ_ALL: 'MESSAGES_READ_ALL',
    NOTIFY_LAB_ABOUT_PHOTOS: 'NOTIFY_LAB_ABOUT_PHOTOS',
    GET_MAGICTOUCH_CUSTOMERS_LIST: 'GET_MAGICTOUCH_CUSTOMERS_LIST',
    MAGICTOUCH_CUSTOMERS_LIST_LOADED: 'MAGICTOUCH_CUSTOMERS_LIST_LOADED',
    GET_MAGICTOUCH_PRACTICE_DOCTORS_LIST: 'GET_MAGICTOUCH_PRACTICE_DOCTORS_LIST',
    MAGICTOUCH_PRACTICE_DOCTORS_LIST_LOADED: 'MAGICTOUCH_PRACTICE_DOCTORS_LIST_LOADED',
    MAGICTOUCH_GET_PRODUCTS_LIST: 'MAGICTOUCH_GET_PRODUCTS_LIST',
    MAGICTOUCH_PRODUCTS_LIST_LOADED: 'MAGICTOUCH_PRODUCTS_LIST_LOADED',
    UPDATE_SHADE_TAB_COORDS: 'UPDATE_SHADE_TAB_COORDS',
    UPDATE_TEXT_ANNOTATIONS: 'UPDATE_TEXT_ANNOTATIONS',
    UPDATE_SHADE_TAB_SETTINGS: 'UPDATE_SHADE_TAB_SETTINGS',
    SAVE_FLIPPED_PHOTO: 'SAVE_FLIPPED_PHOTO',
    SAVE_CROSSHAIR_POSITION: 'SAVE_CROSSHAIR_POSITION',
    UPDATE_GRAPHIC_ANNOTATIONS: 'UPDATE_GRAPHIC_ANNOTATIONS',
    UNDO_SAVED_PHOTO: 'UNDO_SAVED_PHOTO',
    REDO_SAVED_PHOTO: 'REDO_SAVED_PHOTO',
    UNDO_PHOTO_COMPLETED: 'UNDO_PHOTO_COMPLETED',
    REDO_PHOTO_COMPLETED: 'REDO_PHOTO_COMPLETED',
    UPDATE_ALTERNATIVE_ID: 'UPDATE_ALTERNATIVE_ID',
    UPDATE_CASE_DETAILS: 'UPDATE_CASE_DETAILS'

};

export default CaseActionTypes;
