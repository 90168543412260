import workAreaTypes from '../actions/workArea.types';
import CaseActionTypes from "../actions/case.types";
import UIActionTypes from "../actions/ui.types";
import UserActionTypes from '../actions/user.types';
import { IMAGE_SHADE_MAP, IMAGE_CORRECTED, IMAGE_ORIGINAL } from '../constants/imageTypes';
import * as workAreaDefaults from '../constants/workAreaDefauls';

export const initialEditValues = {
    rotateDegrees: 0,
    isGrayScale: false,
    brightness: workAreaDefaults.defaultBrightness,
    contrast: workAreaDefaults.defaultContrast,
    isCrop: false,
    photoProcessingError: '',
};
export const initialMapsValues = {
    colorMapSensitivity: workAreaDefaults.defaultColorMapSensitivity,
    modifierSensitivity: workAreaDefaults.defaultModifierSensitivity,
    //shadeLimits: 255,
    backgroundButtons: [
        {name: 'black', text: 'Black', isCurrent: false},
        {name: 'gray', text: 'Grey',  isCurrent: false},
        {name: 'none', text: 'None',  isCurrent: true},
    ],
    mapTypesButtons: [
        {name: 'colored', text: 'Colored',  isCurrent: true},
        {name: 'outline', text: 'Outline', isCurrent: false},
    ],
    currentBackground: 'none',
    currentMapType: 'colored'
};
export const initialPreferencesValues = {
    themeButtons: [
        {name: 'light', text: 'Light', isCurrent: true},
        {name: 'dark', text: 'Dark',  isCurrent: false},
    ],
    currentTheme: 'light',
    processPhotoWalkthroughButtons: [
        {name: 'on', text: 'on', isCurrent: true},
        {name: 'off', text: 'off',  isCurrent: false},
    ],
    //currentProcessPhotoWalkthrough: localStorage.currentProcessPhotoWalkthrough ? localStorage.currentProcessPhotoWalkthrough : 'on',
    

    selectedShadeTabs: [],
};

function getShadeReferenceAnalysisSettings( profile = null ) {
    let oneklikEnabled = profile ? profile.oneklik : parseJson(localStorage.userProfile, {oneklik : false})?.oneklik;
    if (oneklikEnabled) {
        return {
            shadeReferenceAnalysisButtons: [
                {name: 'on', text: 'on', isCurrent: false },
                {name: 'off', text: 'off',  isCurrent: true },
            ],
            currentShadeReferenceAnalysis: 'off',
        }
    }
    else {
        return {
            shadeReferenceAnalysisButtons: [
                {name: 'on', text: 'on', isCurrent: true },
                {name: 'off', text: 'off',  isCurrent: false, isDisabled: true },
            ],
            currentShadeReferenceAnalysis: 'on',
        }
    }
}

export const initialProcessValues = {
    shadeGuides: [
        /*{ value: 1, name: '148 Studio Vita Clasic' },
        { value: 2, name: 'Origin Live Zirconia' },*/
    ],
    currentShadeGuideId: '1',

    colorCorrectButtons: [
        {text: 'Original', isCurrent: true, name: IMAGE_ORIGINAL, isDisabled: true},
        {text: 'Corrected', isCurrent: false, name: IMAGE_CORRECTED, isDisabled: true},
    ],
    activeImageType: IMAGE_ORIGINAL,

    regionButtons: [
        {text: 'Region On', isCurrent: false, name: 'on', isDisabled: true},
        {text: 'Region Off', isCurrent: true, name: 'off', isDisabled: true},
    ],
    activeRegion: 'off',

    correctedImageButtons: [
        {text: 'View', isCurrent: true, name: 'view', isDisabled: true},
        {text: 'Normalized', isCurrent: false, name: 'norm', isDisabled: true},
    ],
    correctedImageType: 'view',

    isShadeGuideExpanded: true,
    currentPhotoProcessingStep: 0,
};
export const initialState = {
    subMenu: '',
    ...initialEditValues,
    ...initialMapsValues,
    ...initialPreferencesValues,
    ...initialProcessValues,
    ...getShadeReferenceAnalysisSettings(),

    isWallkthroughExpanded: true,
    photoContainerRef: false,

    isWorkAreaProgress: false,
};

export const disabledMapOptions = {
    isShadeMaps: false,
    isModifier: false,
    isAllMaps:false,
};

function parseJson(str, defaultObj = null)
{
    try {
        return JSON.parse(str);
    } catch (e) {
    }
    return defaultObj;
}

function workAreaReducer(state = initialState, action) {
    switch (action.type) {
        case CaseActionTypes.ADD_LOADED_PHOTO:
            return {...state, rotateDegrees: 0, isGrayScale: false, brightness: 0, contrast: 0,};
        case CaseActionTypes.LOAD_CASE:
        case UserActionTypes.SIGN_OUT_SUCCESS:
            return {...initialState, ...getShadeReferenceAnalysisSettings() };
        case workAreaTypes.HIDE_PHOTO_PROCESSING_ERROR:
            return {...state, photoProcessingError: ''};
        case workAreaTypes.PHOTO_PROCESSING_ERROR:
            return {...state, isWorkAreaProgress: false, isCrop: false, photoProcessingError: action.error};
        case workAreaTypes.CROP_IMAGE_SUCCESS:
            return {...state, isWorkAreaProgress: false, isCrop: false, rotateDegrees: 0};
        case workAreaTypes.CROP_IMAGE:
            return {...state, isWorkAreaProgress: true};
        case workAreaTypes.GRAYSCALE_SUCCESS:
            return {...state, isWorkAreaProgress: false, isCrop: false, rotateDegrees: 0};
        case workAreaTypes.GRAYSCALE_IMAGE:
            return {...state, isWorkAreaProgress: true};
        case CaseActionTypes.LOAD_AVAILABLE_SHADE_SYSTEMS_SUCCESS:
            return {
                ...state,
                availableShadeGuides: action.shadeSystems
            };
        case workAreaTypes.SET_PHOTO_CONTAINER_REF:
            return {...state, photoContainerRef: action.ref};

        /**/
        case workAreaTypes.TOGGLE_WALKTHROUGH_EXPAND:
            return {...state, isWallkthroughExpanded: action.isExpanded};

        /* preferences slide actions */
        case workAreaTypes.SET_THEME:
            return {...state, currentTheme: action.name};
        
        case workAreaTypes.SET_SHADE_REFERENCE_ANALYSIS:
            return {...state, currentShadeReferenceAnalysis: action.name};

        /* maps slide actions */
        case workAreaTypes.SET_SHADE_MAPS:
            return {...state, ...disabledMapOptions, isShadeMaps: action.value};
        case workAreaTypes.SET_TRANSLUENCY:
            return {...state, ...disabledMapOptions, isModifier: action.value};
        case workAreaTypes.SET_BACKGROUND:
            return {...state, currentBackground: action.name};
        case workAreaTypes.SET_MAP_TYPE:
            return {...state, currentMapType: action.name};
        case workAreaTypes.SET_ALL_MAPS:
            return {...state, ...disabledMapOptions, isAllMaps: action.value};

        /* edit slide actions */
        case workAreaTypes.SET_MODIFIER_SENSITIVITY:
            return {...state, modifierSensitivity: action.value};
        case workAreaTypes.SET_COLOR_MAP_SENSITIVITY:
            return {...state, colorMapSensitivity: action.value};
        case workAreaTypes.SET_CONTRAST:
            return {...state, contrast: action.value};
        case workAreaTypes.SET_BRIGHTNESS:
            return {...state, brightness: action.value};
        
        case workAreaTypes.SET_GRAY_SCALE:
            return {...state, isGrayScale: action.value};
        case workAreaTypes.ROTATE_IMAGE:
            return {...state, rotateDegrees: action.degrees};
        case workAreaTypes.TOGGLE_CROP:
            return {...state, isCrop: action.value, subMenu: ''};

        /* process actions */
        case workAreaTypes.CHOSE_REGION:
            return {...state, activeRegion: action.name};
        case workAreaTypes.CHANGE_IMAGE_TYPE:
            return {...state, activeImageType: action.name};
        case workAreaTypes.CHANGE_CORRECTED_IMAGE_TYPE:
            return {...state, correctedImageType: action.name};
        case workAreaTypes.EXPAND_SHADE_GUIDE:
            return {...state, isShadeGuideExpanded: true};
        case workAreaTypes.UNEXPAND_SHADE_GUIDE:
            return {...state, isShadeGuideExpanded: false};
        case workAreaTypes.SELECT_SHADE_GUIDE:
            return {...state, currentShadeGuideId: action.guideId};
        case workAreaTypes.UPDATE_SHADE_GUIDE:
            return {...state, shadeGuides: action.shadeGuides};
        /* sub menu actions */
        case workAreaTypes.SHOW_SUB_MENU:
            return {...state, subMenu: action.menu};
        case workAreaTypes.HIDE_SUB_MENU:
        case UIActionTypes.HIDE_WORK_AREA_MENU:
            return {...state, subMenu: ''};
        case CaseActionTypes.SHADE_GUIDE_LOADED:
            return {...state, currentShadeGuideId: action.shadeGuide.id }
        case CaseActionTypes.SELECTED_PHOTO_TYPE:
            return {...state,
                brightness: 0,
                contrast: 0,
                activeImageType: action.photoType,
                currentPhotoProcessingStep: (action.photoType === IMAGE_CORRECTED || action.photoType === IMAGE_SHADE_MAP) ? 0 : state.currentPhotoProcessingStep,
                activeRegion: (action.photoType !== IMAGE_CORRECTED) ? 'off' : state.activeRegion,
            };

        case UIActionTypes.START_PROCESS_PHOTO:
            return {...state, currentPhotoProcessingStep: 1, rotateDegrees: 0};
        case CaseActionTypes.ADD_SHADE_SELECTED:
            return {...state,
                currentPhotoProcessingStep: state.currentPhotoProcessingStep + 1,
            };
        case CaseActionTypes.RESTART_ORIGINAL_IMAGE:
            return {...state,
                currentPhotoProcessingStep: 0,
                shadeValue: '',
                master3dValue: '',
                brightnessValue: '',
            };
        case workAreaTypes.REGION_COLOR_VALUES_RECEIVED:
            return {
                ...state,
                shadeValue: action.result.colorName,
                master3dValue: action.result.master3dName,
                brightnessValue: action.result.brightnessName,
            };
        case CaseActionTypes.PHOTO_METADATA_LOADED:
            return {
                ...state,
                colorMapSensitivity: action.metadata.shadeMapSensitivity ?? state.colorMapSensitivity,
                modifierSensitivity: action.metadata.modifierSensitivity ?? state.modifierSensitivity,
                shadeLimits: action.metadata.shadeLimits ?? 255,
                currentBackground: action.metadata.background ?? 'none',
            };
        case CaseActionTypes.SET_ACTIVE_PHOTO:
            console.log('set active photo');

            return {...state, rotateDegrees: 0,
                isGrayScale: false,
                brightness: 0,
                contrast: 0,
                isCrop: false,
                photoProcessingError: '', 
                currentPhotoProcessingStep: 0 } ;
        case CaseActionTypes.SAVE_ROTATED_PHOTO:
            return {
                ...state,
                rotateDegrees: 0,
            }
        case UserActionTypes.USER_LOADED:
            return {
                ...state,
                ...getShadeReferenceAnalysisSettings(action.profile),
            }
        case UserActionTypes.USER_NOT_AUTHENTICATED:
            return {...initialState, ...getShadeReferenceAnalysisSettings() };
        default:
            return state;
    }
}

export default workAreaReducer;
