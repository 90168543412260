import CaseActionTypes from "../actions/case.types";
import * as caseFuncs from "../helpers/case";
import { IMAGE_CORRECTED, IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from "../constants/imageTypes";
import UserActionTypes from "../actions/user.types";
import workAreaTypes from "../actions/workArea.types";
import photoUtils from "../libraries/photoUtils";
import { OPERATION_FLIP, OPERATION_ROTATE } from "../constants/photoOperations";

// TODO change inititalState on auth ready
const secureNotesInitialState = {
    casePhotos: [],
    caseMessages: [],
    notLoadedPhotos: [],
    notSavedPhotos: [],
    caseMessagePhotoCount: 0,
    loadedCaseMessagePhotoCount: 0,
    casePhotosLoaded: false,
    caseMessagesLoaded: false,
    savingCaseMessageProcessFinished: false,
    savingCaseMessagePhotoProcessFinished: false,
    savedPhotoCount: 0,
    photosToSaveCount: 0,
};
const qcInitialState = {
    leftqc: {
        shadeValue: '',
        master3dValue: '',
        brightnessValue: '',
        photo: null,
        rotation: 0,
        zoom: 1,

    },
    rightqc: {
        shadeValue: '',
        master3dValue: '',
        brightnessValue: '',
        photo: null,
        rotation: 0,
        zoom: 1,
    },
    isQcGrayScale: false,
    isQcCrosshair: false,
    isQcOriginal: false,
    isQcMode: false,
};

const initialState = {
    activePhotoId: '',

    photos: [],

    currentCase: false,
    cases: [],
    availableShadeGuides: [],
    shadeGuide: null,
    shadeTabsByKey: {},
    shadeTabsLoadingByKey: {
        left: false,
        right: false,
    },

    //createdCaseId: null,
    newCaseNum: '',
    defaultSettings: {
        limits: 4,
        other: false,
    },

    isAllMessagesRead: true,
    hasUnreadReceived: false,

    connectedSpecialists: [],
    labs: [],
    caseId: '',
    roles: [],

    magictouch_customers: [],
    magictouch_practice_doctors: [],
    magictouch_products: [],

	last_undo_operation: '',
	last_redo_operation: '',

    ...secureNotesInitialState,
    ...qcInitialState,
};

function caseReducer(state = initialState, action) {
    switch (action.type) {
		case CaseActionTypes.LOAD_CASE:
		case UserActionTypes.SIGN_OUT_SUCCESS:
			return initialState;
		case workAreaTypes.CROP_IMAGE_SUCCESS:
            let croppedPhoto = caseFuncs.initializePhotos([ action.response ]);
			let photos = croppedPhoto.concat(state.photos);
			return { ...state, photos: photos };
		case workAreaTypes.GRAYSCALE_SUCCESS:
			let grayPhoto = caseFuncs.initializePhotos([ action.response ]);
			let photosUpdated = grayPhoto.concat(state.photos);
			return { ...state, photos: photosUpdated };
		case CaseActionTypes.GET_LABS_SUCCESS:
			return { ...state, labs: action.labs };
		case CaseActionTypes.GET_CONNECTED_SPECIALISTS_SUCCESS:
			return { ...state, connectedSpecialists: action.specialists };

		case CaseActionTypes.DELETE_PHOTO_SUCCESS:
			return { ...state, photos: state.photos.filter((photo) => photo.id !== action.photoId) };
		case CaseActionTypes.GET_NEW_CASE_NUM_SUCCESS:
			return { ...state, newCaseNum: action.caseNum };
		//case CaseActionTypes.CREATE_CASE_SUCCESS:
		//	return { ...state, createdCaseId: action.caseId };
		case CaseActionTypes.CASE_DELETED:
			return {
				...state,
				cases: state.cases.filter((caseInfo) => {
					return caseInfo.id !== action.caseId;
				}),
			};
		case CaseActionTypes.SET_ACTIVE_PHOTO:
            return { ...state, activePhotoId: action.photoId,
                photos: state.photos.map((item,index) => {
                    if (item.id !== action.photoId) {
                        if (state.isQcMode) return item;
                        else {
                            photoUtils.clearCanvases([
                                item.imgCorrected,
                                item.imgModifier,
                                item.imgRegionCorrected,
                                item.imgRegionModifier,
                                item.imgRegionModifiers,
                                item.imgRegionShadeMap,
                                item.imgRegionViewCorrected,
                                item.imgShadeMap,
                                item.imgTriple1,
                                item.imgTriple2,
                                item.imgViewCorrected
                            ]);
                            return {...item,
                                imgCorrected: null,
                                imgModifier: null,
                                imgModifierMask: null,
                                imgRegionCorrected: null,
                                imgRegionModifier: null,
                                imgRegionModifierMask: null,
                                imgRegionModifiers: null,
                                imgRegionShadeMap: null,
                                imgRegionShadeMapMask: null,
                                imgRegionViewCorrected: null,
                                imgShadeMap: null,
                                imgShadeMapMask: null,
                                imgTriple1: null,
                                imgTriple1Mask: null,
                                imgTriple2: null,
                                imgTriple2Mask: null,
                                corrected: false,
                                imgViewCorrected: null,
                            };
                        }
                    }
                    else {
                        return item;
                    }
                }),
            };
		case CaseActionTypes.LOAD_CASE_SUCCESS:
			let newPhotos = caseFuncs.initializePhotos(action.currentCase.photos);
			let oldPhotos = state.photos.filter((p) => newPhotos.some((pp) => pp.id === p.id));
			newPhotos = newPhotos.filter((p) => !oldPhotos.some((pp) => pp.id === p.id));
			return {
				...state,
				currentCase: caseFuncs.modifyCaseInfo(action.currentCase),
				photos: newPhotos.concat(oldPhotos),
			};
		case CaseActionTypes.ADD_LOAD_CASES_SUCCESS:
			return { ...state, cases: [...state.cases, ...action.cases]};
		case CaseActionTypes.LOAD_CASES_SUCCESS:
			return { ...state, cases: action.cases};
		case CaseActionTypes.SHADE_TABS_IS_LOADING:
			let shadeTabsLoadingByKey = state.shadeTabsLoadingByKey;
			shadeTabsLoadingByKey[action.storeKey] = action.isLoading;
			return { ...state, shadeTabsLoadingByKey: shadeTabsLoadingByKey };
		case CaseActionTypes.SHADE_GUIDE_BY_KEY_LOADED:
			let shadeTabsByKey = state.shadeTabsByKey;
			shadeTabsByKey[action.storeKey] = action.shadeGuides;
			return { ...state, shadeTabsByKey: shadeTabsByKey };
		case CaseActionTypes.SHADE_GUIDE_LOADED:
			let temp = { ...state, shadeGuide: action.shadeGuide };
			return { ...temp, currentModifierId: caseFuncs.getDefaultModifierId(temp, temp.standardModifiers) };
		case CaseActionTypes.LOAD_LAST_USED_SETTINGS_SUCCESS:
			return {
				...state,
				defaultSettings: action.settings,
			};
		case CaseActionTypes.LOAD_AVAILABLE_SHADE_SYSTEMS_SUCCESS:
			return {
				...state,
				availableShadeGuides: action.shadeSystems,
			};
		case CaseActionTypes.STANDARD_MODIFIERS_LOADED:
			return {
				...state,
				standardModifiers: action.standardModifyers,
				currentModifierId: caseFuncs.getDefaultModifierId(state, action.standardModifyers),
			};
		case CaseActionTypes.CHANGE_CURRENT_MODIFIER:
			return {
				...state,
				currentModifierId: action.value,
			};
		case CaseActionTypes.ADD_LOADED_PHOTO:
			return { ...state, photos: [action.photo, ...state.photos] };
		case CaseActionTypes.PHOTO_ORIGINAL_LOADED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photo.id) {
						return { ...item, imgOriginal: action.photo.image, imgOriginalSrc: action.photo.imageSrc, imgSize: action.photo.imageSize };
					}

					return item;
				}),
			};
		case CaseActionTypes.PHOTO_CORRECTED_LOADED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photoId) {
                        photoUtils.clearCanvases([
                            item.imgCorrected,
							item.imgViewCorrected,
							item.imgRegionCorrected,
							item.imgRegionViewCorrected
                        ]);
						return {
							...item,
							imgCorrected: action.image,
							imgViewCorrected: action.imageView,
							currentPhotoType: IMAGE_CORRECTED,
							corrected: true,
							last_undo_operation: '', last_redo_operation: '',
							metadata: { ...item.metadata, manualCorrected: true },
						};
					} else return item;
				}),
			};

		case CaseActionTypes.SHADE_MAP_GENERATED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photoId) {
						let imageData = caseFuncs.setImageAndMaskData(action, item);
						return { ...item, last_undo_operation: '', last_redo_operation: '', ...imageData };
					} else return item;
				}),
			};
		case CaseActionTypes.TRIPLE_IMAGES_GENERATED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photoId) {
						let imageData = caseFuncs.setImageAndMaskData(action, item);
						return { ...item, last_undo_operation: '', last_redo_operation: '', ...imageData };
					} else return item;
				}),
			};
		case CaseActionTypes.DOUBLE_IMAGE_GENERATED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photoId) {
						let imageData = caseFuncs.setImageAndMaskData(action, item);
						return { ...item, last_undo_operation: '', last_redo_operation: '', ...imageData };
					} else return item;
				}),
			};
		case CaseActionTypes.REGION_CORRECTED_GENERATED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === action.photoId) {
						photoUtils.clearCanvases([
							item.imgRegionCorrected,
							item.imgRegionViewCorrected
                        ]);
						return { ...item, last_undo_operation: '', imgRegionCorrected: action.image, imgRegionViewCorrected: action.imageView };
					} else return item;
				}),
			};
		case CaseActionTypes.HIDE_REGION:
			return {
				...state,
			};
		case CaseActionTypes.SET_SHADE_TABS_SUCCESS:
			const newValue = JSON.parse(state.defaultSettings.other ?? '{}');
			newValue.shadeTabFilter = JSON.parse(action.selectedTabsIds).join();
			return {
				...state,
				defaultSettings: {
					...state.defaultSettings,
					other: JSON.stringify(newValue),
				},
            };
		case workAreaTypes.CLOSE_WALKTHROUGH:
            //if (action.notShow) {
                let newValue1 = JSON.parse(state.defaultSettings.other ?? '{}');
                newValue1.showWalkthrough = 'off';
                return {
                    ...state,
                    defaultSettings: {
                        ...state.defaultSettings,
                        other: JSON.stringify(newValue1),
                    },
                };
            //}
            //else return state;

        case workAreaTypes.SET_PROCESS_PHOTO_WALKTHROUGH:
            let newValue2 = JSON.parse(state.defaultSettings.other ?? '{}');
			newValue2.showWalkthrough = action.name;
            return {
				...state,
				defaultSettings: {
					...state.defaultSettings,
					other: JSON.stringify(newValue2),
				},
            };
        case workAreaTypes.SET_SHADE_LIMITS:
            return {...state,
                        defaultSettings: {
                            ...state.defaultSettings,
                            limits: action.value,
                        }
                    };
		case CaseActionTypes.ADD_SHADE_SELECTED:
			const photoId = state.activePhotoId;
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === photoId) {
						const oldMeta = item.metadata
							? item.metadata
							: { colors: [], refColors: [], studioColors: [], coordinates: [] };
						const oldColors = oldMeta.colors ? oldMeta.colors : [];
						const oldRefColors = oldMeta.refColors ? oldMeta.refColors : [];
						const oldCoords = oldMeta.coordinates ? oldMeta.coordinates : [];
						const oldStudioColors = oldMeta.studioColors ? oldMeta.studioColors : [];
						return {
							...item,
							metadata: {
								...oldMeta,
								colors: [...oldColors, action.color],
								refColors: [...oldRefColors, action.refColor],
								studioColors:
									action.studioColor > 0 ? [...oldStudioColors, action.studioColor] : oldStudioColors,
								coordinates: action.coordinate ? [...oldCoords, action.coordinate] : oldCoords,
							},
						};
					} else return item;
				}),
			};
		case CaseActionTypes.SELECTED_PHOTO_TYPE:
			if (Array.of(IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW, IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER).includes(action.photoType) ) {
				let newValue2 = JSON.parse(state.defaultSettings.other ?? '{}');
				newValue2.lastMap = action.photoType;
				return {
					...state,
					defaultSettings: {
						...state.defaultSettings,
						other: JSON.stringify(newValue2),
					},
					photos: state.photos.map((item, index) => {
						if (item.id === state.activePhotoId) {
							let imageData = caseFuncs.updateOnImageTypeChange(action, item);
							return { ...item, currentPhotoType: action.photoType, ...imageData };
						} else return item;
					}),
				};
			}
			else 
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						let imageData = caseFuncs.updateOnImageTypeChange(action, item);
						return { ...item, currentPhotoType: action.photoType, ...imageData };
					} else return item;
				}),
			};
		case CaseActionTypes.RESTART_ORIGINAL_IMAGE:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
                        photoUtils.clearCanvases([
                            item.imgCorrected,
                            item.imgModifier,
                            item.imgRegionCorrected,
                            item.imgRegionModifier,
                            item.imgRegionModifiers,
                            item.imgRegionShadeMap,
                            item.imgRegionViewCorrected,
                            item.imgShadeMap,
                            item.imgTriple1,
                            item.imgTriple2,
                            item.imgViewCorrected
                        ]);
						return {
							...item,
							metadata: {
								...item.metadata,
								colors: [],
								refColors: [],
								studioColors: [],
                                coordinates: [],
                                manualCorrected: false,
                                valueBarShade: '',
                                valueBarMaster: '',
                                valueBarBrightness: '',
                                shadeLabels: [],
                                otherLabels: [],
                                regionRect: null,
                                valueRect: null,
							},
							imgCorrected: null,
							imgModifier: null,
							imgModifierMask: null,
							imgRegionCorrected: null,
							imgRegionModifier: null,
							imgRegionModifierMask: null,
							imgRegionModifiers: null,
							imgRegionShadeMap: null,
							imgRegionShadeMapMask: null,
							imgRegionViewCorrected: null,
							imgShadeMap: null,
							imgShadeMapMask: null,
							imgTriple1: null,
							imgTriple1Mask: null,
							imgTriple2: null,
                            imgTriple2Mask: null,
                            corrected: false,
                            modifierLabels: [],
                            modifierSettings: null,
                            shadeMapLabels: [],
                            shadeMapSettings: null,
                            tripleViewSettings: null,
							doubleViewSettings: null,
                            imgViewCorrected: null,

						};
					} else return item;
				}),
			};
		case UserActionTypes.CREDITS_CHARGED:
            return { ...state, currentCase: { ...state.currentCase, is_charged: "1" } };
		case UserActionTypes.USER_NOT_AUTHENTICATED:
			return initialState;
		case CaseActionTypes.CASE_MESSAGES_LOADED:
			return { ...state, caseMessages: action.payload, caseMessagesLoaded: true };
		case CaseActionTypes.ALL_CASE_PHOTO_LOADED:
			return { ...state, caseMessagePhotoCount: action.payload, casePhotosLoaded: action.payload === 0 };
		case CaseActionTypes.CASE_MESSAGE_PHOTO_LOADED:
			const targetPhoto = action.payload.data;
			const { data, loaded } = action.payload;
			let casePhotos = state.casePhotos;
			let loadedCaseMessagePhotoCount = state.loadedCaseMessagePhotoCount;

			let notLoadedPhotos = state.notLoadedPhotos;

			if (data && Object.keys(data).length && loaded) {
				casePhotos = [...casePhotos, targetPhoto];
				loadedCaseMessagePhotoCount += 1;
			} else {
				notLoadedPhotos.push(data.filename);
			}
			const casePhotosLoaded =
				loadedCaseMessagePhotoCount + notLoadedPhotos.length === state.caseMessagePhotoCount;
			return { ...state, casePhotos, loadedCaseMessagePhotoCount, casePhotosLoaded, notLoadedPhotos };
		case CaseActionTypes.EMPTY_CASE_MESSAGE_PHOTOS:
			return { ...state, ...secureNotesInitialState };
		case CaseActionTypes.SET_ACTIVE_CASE_ID:
			return { ...state, caseId: action.payload };
		case CaseActionTypes.SET_PHOTOS_TO_SAVE_COUNT:
			return {
				...state,
				photosToSaveCount: action.payload,
				savingCaseMessagePhotoProcessFinished: action.payload === 0,
			};
		case CaseActionTypes.CASE_MESSAGE_PHOTO_SAVE_FINISHED:
			const { saved, name } = action.payload;

			let savedPhotoCount = state.savedPhotoCount;
			const notSavedPhotos = state.notSavedPhotos;

			if (saved) {
				savedPhotoCount++;
			} else {
				notSavedPhotos.push(name);
			}
			const savingCaseMessagePhotoProcessFinished =
				state.photosToSaveCount === savedPhotoCount + notSavedPhotos.length;
			return { ...state, savedPhotoCount, savingCaseMessagePhotoProcessFinished, notSavedPhotos };
		case CaseActionTypes.CASE_MESSAGES_SAVE_FINISHED:
			return { ...state, savingCaseMessageProcessFinished: true };
		case CaseActionTypes.RESET_CASE_MESSAGES_SAVED:
			return { ...state, savingCaseMessageProcessFinished: false };
		case CaseActionTypes.RESET_CASE_MESSAGE_PHOTOS_SAVED:
			return {
				...state,
				savingCaseMessagePhotoProcessFinished: false,
				savedPhotoCount: 0,
				photosToSaveCount: 0,
				notLoadedPhotos: [],
			};
		case CaseActionTypes.RESET_CASE_MESSAGE_PHOTOS_LOADED:
			return { ...state, casePhotosLoaded: false, notLoadedPhotos: [] };
		case CaseActionTypes.SET_ROLES:
			const roles = action.payload || [];
			return { ...state, roles: roles.map((role) => ({ id: role.id, name: role.role })) };
		case CaseActionTypes.STANDARD_VIEW_COLORS_LOADED:
			return { ...state, standardViewColors: action.data };
		case CaseActionTypes.PHOTO_METADATA_UPDATED:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					// eslint-disable-next-line eqeqeq
					if (item.id === action.photoId) {
						return { ...item, metadata: { ...item.metadata, ...action.metadata } };
					} else return item;
				}),
			};
		case CaseActionTypes.ADD_SHADEMAP_LABEL:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return { ...item, last_undo_operation: '', last_redo_operation: '', shadeMapLabels: [...item.shadeMapLabels, action.point ]};
					} else return item;
				}),
			};
		
		case CaseActionTypes.UPDATE_SHADE_TRANS_POSITION:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return { ...item, last_undo_operation: '', last_redo_operation: '', metadata: { ...item.metadata,  shadeTransPos: {...item.shadeMapTransPos, ...action.payload }}};
					} else return item;
				})
			};
        case CaseActionTypes.UPDATE_SHADE_TAB_COORDS:
            return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
						return { ...item, shadeTabCoords: {...item.shadeTabCoords, ...action.coords }};
					} else return item;
                }),
            }
        case CaseActionTypes.UPDATE_TEXT_ANNOTATIONS:
            return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
                        return { ...item, textAnnotations: action.payload  };
                    } else return item;
                }),
            }
		case CaseActionTypes.UPDATE_GRAPHIC_ANNOTATIONS:
			return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
                        return { ...item, graphic_annotations: action.payload  };
                    } else return item;
                }),
            }
		case CaseActionTypes.SAVE_ROTATED_PHOTO:
			return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
                        return { ...item, last_undo_operation: OPERATION_ROTATE, last_undo_value: action.rotation, last_redo_operation: ''  };
                    } else return item;
                }),
            }
			case CaseActionTypes.SAVE_FLIPPED_PHOTO:
				return {
					...state,
					photos: state.photos.map((item, index) => {
						if (item.id === state.activePhotoId) {
							return { ...item, last_undo_operation: OPERATION_FLIP, last_undo_value: action.flipOrientation, last_redo_operation: ''  };
						} else return item;
					}),
				}
			case CaseActionTypes.UNDO_PHOTO_COMPLETED:
				return {
					...state,
					photos: state.photos.map((item, index) => {
						if (item.id === state.activePhotoId) {
							return { ...item, last_redo_operation: item.last_undo_operation, last_redo_value: item.last_undo_value, last_undo_operation: '' };
						} else return item;
					}),
				}
			case CaseActionTypes.REDO_PHOTO_COMPLETED:
				return {
					...state,
					photos: state.photos.map((item, index) => {
						if (item.id === state.activePhotoId) {
							return { ...item, last_undo_operation: item.last_redo_operation, last_undo_value: item.last_redo_value, last_redo_operation: '' };
						} else return item;
					}),
				}
		/*case CaseActionTypes.SAVE_CROSSHAIR_POSITION:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return { ...item, crossHairPos: { x: action.payload.x, y: action.payload.y }};
					} else return item;
				}),
			}*/
        case CaseActionTypes.UPDATE_SHADE_TAB_SETTINGS:
            return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
                        return { ...item, last_undo_operation: '', last_redo_operation: '', shadeTabSettings: {...item.shadeTabSettings, ...action.settings }};
                    } else return item;
                }),
            }
		case CaseActionTypes.ADD_MODIFIER_LABEL:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return { ...item, last_undo_operation: '', last_redo_operation: '', modifierLabels: [ ...item.modifierLabels, action.point ] };
					} else return item;
				}),
			};
		case CaseActionTypes.REMOVE_SHADE_LABEL:
			let labelType = action.index.slice(0, 2);
			let idx = parseInt(action.index.slice(2));
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						if (labelType === "sm")
					    {
                            let smLabels = [...item.shadeMapLabels];
                            smLabels.splice(idx, 1);
							return {...item, shadeMapLabels: smLabels};
                        }
                        else
						{
                            let mrLabels = [...item.modifierLabels];
                            mrLabels.splice(idx, 1);
                            return {...item, modifierLabels: mrLabels};
						};
					} else return item;
				}),
            };
        case CaseActionTypes.PHOTO_METADATA_LOADED:
            return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
							shadeMapLabels: action.metadata.shadeLabels ?? [],
							modifierLabels: action.metadata.otherLabels ?? []
						};
					} else return item;
				}),
            };
        case CaseActionTypes.UPDATE_SHADE_MAP_LABELS:
            return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
                            shadeMapLabels: action.shLabels ?? item.shadeMapLabels,
                            modifierLabels: action.mrLabels ?? item.modifierLabels,
						};
					} else return item;
				}),
            };
        case CaseActionTypes.SET_HIDE_X_MARKS:
            return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
                            hideXmarks: action.value
						};
					} else return item;
				}),
            };
        case CaseActionTypes.SET_SHOW_SHADE_TABS:
            return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
                            showShadeTabs: action.value
						};
					} else return item;
				}),
            };
        case CaseActionTypes.SET_SHOW_TEXT_ANNOTATIONS:
            return {
                ...state,
                photos: state.photos.map((item, index) => {
                    if (item.id === state.activePhotoId) {
                        return {
                            ...item,
                            showTextAnnotations: action.payload
                        };
                    } else return item;
                }),
            };
		case CaseActionTypes.SET_SHOW_GRAPHICS_ANNOTATIONS:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
							showGraphicsAnnotations: action.payload
						};
					} else return item;
				}),
			};
		case CaseActionTypes.SET_SHOW_CROSS_HAIR:
			return {
				...state,
				photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
							showCrossHair: action.payload
						};
					} else return item;
				}),
			};
		
        case CaseActionTypes.SET_LEFT_QC_IMAGE:
            return {
                ...state,
                leftqc: {...state.leftqc, photo: action.photo, crosshairPos: null }
            };
        case CaseActionTypes.SET_RIGHT_QC_IMAGE:
            return {
                ...state,
                rightqc: {...state.rightqc, photo: action.photo, crosshairPos: null }
            };
        case CaseActionTypes.SET_QC_MODE:
            return {
                ...state,
                ...qcInitialState,
                isQcMode: action.value,
            }
        case CaseActionTypes.UPDATE_LEFT_QC_VALUES:
            return {
                ...state,
                leftqc: {...state.leftqc, ...action.values }
            }
        case CaseActionTypes.UPDATE_RIGHT_QC_VALUES:
            return {
                ...state,
                rightqc: {...state.rightqc, ...action.values }
            }
        case CaseActionTypes.UPDATE_QC_VALUES:
            return {
                ...state,
                ...action.values
            }
        case CaseActionTypes.SET_LEFT_RIGHT_SHADE:
            return {
                ...state,
                photos: state.photos.map((item, index) => {
					if (item.id === state.activePhotoId) {
						return {
							...item,
                            metadata:
                            (action.isLeft) 
                            ? { 
                                ...item.metadata,
                                shadeTabLabel: action.shadeName,
                            } 
                            : {
                                ...item.metadata,
                                rightShadeTabLabel: action.shadeName,
                            }
						};
					} else return item;
				}),
            }
        case CaseActionTypes.MESSAGES_READ_ALL:
            return {
                ...state,
                isAllMessagesRead: action.allSentRead,
                hasUnreadReceived: action.hasUnreadReceived,
            }
        case CaseActionTypes.MAGICTOUCH_CUSTOMERS_LIST_LOADED:
            return {
                ...state,
                magictouch_customers: action.payload ?? []
            }
        case CaseActionTypes.MAGICTOUCH_PRACTICE_DOCTORS_LIST_LOADED:
            return {
                ...state,
                magictouch_practice_doctors: action.payload ?? []
            }
        case CaseActionTypes.MAGICTOUCH_PRODUCTS_LIST_LOADED:
            return {
                ...state,
                magictouch_products: action.payload ?? []
            }
		case CaseActionTypes.UPDATE_ALTERNATIVE_ID:
			return {
				...state,
				cases: state.cases.map((caseInfo) => {
					if (caseInfo.id == action.payload.caseId) {
						return {...caseInfo, alt_id: action.payload.altId}
					}
					else
						return caseInfo;
				}),
			};
		case CaseActionTypes.UPDATE_CASE_DETAILS:
			return {
				...state,
				cases: state.cases.map((caseInfo) => {
					if (caseInfo.id == action.payload.caseId) {
						const editData = action.payload.editData
						return {...caseInfo, doctor_name: editData.doctorFirst+" "+editData.doctorLast, dentist_first_name: editData.doctorFirst, dentist_last_name: editData.doctorLast, patient_first: editData.patientFirst, patient_last: editData.patientLast}
					}
					else
						return caseInfo;
				}),
			};
		case CaseActionTypes.CASE_COMPLETED:
			return {
				...state,
				currentCase: { ...state.currentCase, status: 'done' },
				cases: state.cases.map((caseInfo) => {
					if (caseInfo.id == action.caseId) {
						return {...caseInfo, status: 'done'}
					}
					else
						return caseInfo;
				}),
			};
		case CaseActionTypes.CASE_RENEWED:
			return {
				...state,
				currentCase: { ...state.currentCase, status: 'new' },
				cases: state.cases.map((caseInfo) => {
					if (caseInfo.id == action.caseId) {
						return {...caseInfo, status: 'new'}
					}
					else
						return caseInfo;
				}),
			};
		default:
			return state;
	}
}

export default caseReducer;
