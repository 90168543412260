import { IMAGE_ORIGINAL, IMAGE_CORRECTED, IMAGE_SHADE_MAP, IMAGE_MODIFIER, IMAGE_TRIPLE_VIEW, IMAGE_DOUBLE_VIEW } from "../constants/imageTypes";

export const modifyBrightness = brightness => {
    let num = brightness / 256;
    return Math.round(num * 100) / 100;
};
export const modifyContrast = contrast => {
    return contrast / 2.56;
};
export const modifyColorSensitivity = sensitivity => {
    return sensitivity;
};

export const getImageDimensions = (image, containerWidth, containerHeight) => {
    let imageWidth = image.width;
    let imageHeight = image.height;

    let newImageWidth, newImageHeight, ratio, ratioXmarks;

    let aspectWidth = containerWidth / imageWidth;
    let aspectHeight = containerHeight / imageHeight;

    if (aspectWidth  < aspectHeight)
    {
        ratioXmarks = 1;
        ratio = aspectWidth;
    }
    else{
        ratioXmarks = 1;
        ratio = aspectHeight;
    }

    newImageHeight = ratio * imageHeight;
    newImageWidth = ratio * imageWidth;

    if (imageHeight > 3000 || imageWidth > 4000)
    {
        let aWidth = 4000 / imageWidth;
        let aHeight = 3000 / imageHeight;
        if (aWidth  < aHeight)
        {
            ratioXmarks = imageWidth / 4000;
        }
        else{
            ratioXmarks = imageHeight / 3000;
        }
    }

    return {
        image: image,
        imageWidth: newImageWidth,
        imageHeight: newImageHeight,
        imageX: (containerWidth - newImageWidth) / 2,
        imageY: (containerHeight - newImageHeight) / 2,
        ratio: ratio,
        ratioX: aspectWidth,
        ratioY: aspectHeight,
        ratioXmarks: ratioXmarks,
        containerWidth: containerWidth,
        containerHeight: containerHeight
    };
};

export const getCurrentViewImage = (photo, containerWidth, containerHeight, isNormalized) => {
    switch (photo.currentPhotoType)
    {
        case IMAGE_ORIGINAL:
            return photo.imgRegionOriginal ?? photo.imgOriginal;
        case IMAGE_CORRECTED:
            return (isNormalized ) ? photo.imgCorrected : photo.imgViewCorrected;
        case IMAGE_SHADE_MAP:
            return photo.imgRegionShadeMap ?? photo.imgShadeMap;
        case IMAGE_MODIFIER:
            return photo.imgRegionModifier ?? photo.imgModifier;
        case IMAGE_TRIPLE_VIEW:
            if (!photo.imgTriple1 || !photo.imgTriple2) return null;
            let ratio1 = Math.max(photo.imgTriple1.width / containerWidth, photo.imgTriple1.height / containerHeight);
            let ratio2 = Math.max(photo.imgTriple2.width / containerWidth, photo.imgTriple2.height / containerHeight);
            return (ratio1 < ratio2) ? photo.imgTriple1 : photo.imgTriple2;
        case IMAGE_DOUBLE_VIEW:
            return photo.imgCorrected;
        default:
            return photo.imgRegionOriginal ?? photo.imgOriginal;
    }
}

export const getCurrentMask = (photo, containerWidth, containerHeight) => {
    switch (photo.currentPhotoType)
    {
        case IMAGE_SHADE_MAP:
            return photo.imgRegionShadeMapMask ?? photo.imgShadeMapMask;
        case IMAGE_MODIFIER:
            return photo.imgRegionModifierMask ?? photo.imgModifierMask;
        case IMAGE_TRIPLE_VIEW:
            let ratio1 = Math.max(photo.imgTriple1.width / containerWidth, photo.imgTriple1.height / containerHeight);
            let ratio2 = Math.max(photo.imgTriple2.width / containerWidth, photo.imgTriple2.height / containerHeight);
            return (ratio1 < ratio2) ? photo.imgTriple1Mask : photo.imgTriple2Mask;
        default:
            return null;
    }
}
