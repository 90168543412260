import PropTypes from 'prop-types';
import { dayOfWeekName, monthName, monthString } from './date';
import { IMAGE_DOUBLE_VIEW, IMAGE_MODIFIER, IMAGE_ORIGINAL, IMAGE_SHADE_MAP, IMAGE_TRIPLE_VIEW } from '../constants/imageTypes';
import * as texts from "../constants/texts";
import photoUtils from '../libraries/photoUtils';

export const modifyCaseInfo = (caseInfo) => {
    if (!caseInfo) {
        return false;
    }

    let date = new Date(caseInfo.date);

    let modifyedInfo = {
        dateDay: date.getDate(),
        dateDayOfWeek: dayOfWeekName(date),
        dateMonth: monthName(date),
        dateYear: date.getFullYear(),

    };
    return {...caseInfo, ...modifyedInfo};
};

export const initializePhotos = (photos) => {
    return  photos.map(photo => {

        return {
            ...photo,
            imgOriginal : null,
            imgOriginalSrc: null,
            imgCorrected: null,
            imgViewCorrected: null,
            imgShadeMap: null,
            imgModifier: null,
            currentPhotoType: IMAGE_ORIGINAL,
            currentMapSettings: null,
            currentDoubleMapSettings: null,
            shadeMapSettings: null,
            modifierSettings: null,
            tripleViewSettings: null,
            doubleViewSettings: null,
            imgRegionOriginal: null,
            imgRegionCorrected: null,
            imgRegionViewCorrected: null,
            imgRegionShadeMap: null,
            imgRegionModifiers: null,
            imgTriple1: null,
            imgTriple2: null,
            imgTriple1Mask: null,
            imgTriple2Mask: null,
            imgDouble: null,
            imgDoubleMask: null,
            shadeMapLabels: [],
            modifierLabels: [],
            hideXmarks: true,
            showShadeTabs: false,
            shadeTabCoords: photo.metadata?.shadeTabCoords ?? { left: {x: -1, y: -1}, right: {x: -1, y:-1} },
            shadeTabSettings: photo.metadata?.shadeMapSettings ?? { left: {color: '#000000', fontSize: 40}, right: {color: '#000000', fontSize: 40} },
            textAnnotations: photo.textannotations ?? [],
            showTextAnnotations: true,
            showGraphicsAnnotations: true
        };
    });

};

export const modifyCasesList = (cases) => {
    return  cases.map(caseInfo => {
        let d = new Date(caseInfo.date);

        return {
            id: caseInfo.id,
            systemDate: caseInfo.date,
            date: monthString(d) + '/' + d.getUTCDate() + '/' + d.getUTCFullYear(),
            doctorFirst: caseInfo.dentist_first_name,
            doctorLast: caseInfo.dentist_last_name,
            patient: `${caseInfo.patient_first} ${caseInfo.patient_last}`,
            patientFirst: caseInfo.patient_first,
            patientLast: caseInfo.patient_last,
            lab: caseInfo.lab_form ? caseInfo.lab_form : '',
            labId: caseInfo.lab_id ? caseInfo.lab_id : 0,
            specialist: caseInfo.spec_id ? caseInfo.spec_id : 0,
            case: caseInfo.your_case_id,
            imagesCount: caseInfo.images_count,
            photoIds: caseInfo.photo_ids,
            status: caseInfo.status,
            is_ascend: caseInfo.is_ascend,
            alt_id: caseInfo.alt_id
        };
    });
};

export const getDefaultModifierId = (caseState, modifiers) => {

    if (!caseState)
    {
        return null;
    }

    if (!modifiers)
        return null;

    if (!caseState.shadeGuide)
        return null;

    let shadeGuideId = caseState.shadeGuide.id ?? 0;
    // eslint-disable-next-line eqeqeq
    let standardModifiers = modifiers.find(item => item.id == shadeGuideId);
    let modifierId = (standardModifiers && standardModifiers.modifiers && standardModifiers.modifiers.length > 0)
    ? standardModifiers.modifiers[0].id : null;

    return modifierId;
};

export const setImageAndMaskData = (action, item) => {
    let imageData = {};

    switch (action.imageType) {
        case IMAGE_MODIFIER:
            photoUtils.clearCanvases([
                item.imgRegionModifier,
                item.imgModifier
            ]);
            imageData =
            action.isRegion
            ? { imgRegionModifier : action.image, imgRegionModifierMask: action.mask, modifierSettings: action.params }
            : { imgModifier : action.image, imgModifierMask: action.mask, modifierSettings: action.params };
        break;
        case IMAGE_SHADE_MAP:
            photoUtils.clearCanvases([
                item.imgRegionShadeMap,
                item.imgShadeMap
            ]);
            imageData =
            action.isRegion
            ? { imgRegionShadeMap : action.image, imgRegionShadeMapMask: action.mask, shadeMapSettings: action.params }
            : { imgShadeMap : action.image, imgShadeMapMask: action.mask, shadeMapSettings: action.params };
        break;
        case IMAGE_TRIPLE_VIEW:
            photoUtils.clearCanvases([
                item.imgTriple1,
                item.imgTriple2
            ]);
            imageData = {
                imgTriple1: action.result.imageResult1,
                imgTriple2: action.result.imageResult2,
                imgTriple1Mask: action.result.maskResult1,
                imgTriple2Mask: action.result.maskResult2,
                tripleViewSettings: action.params,
                currentMapSettings: action.params,
            };
        break;
        case IMAGE_DOUBLE_VIEW:
            photoUtils.clearCanvases([
                item.imgDouble
            ]);
            imageData = {
                imgDouble: action.result.imageResult,
                imgDoubleMask: action.result.maskResult,
                currentDoubleMapSettings: action.params,
                doubleViewSettings: action.params
            };
        break;
        default:
            break;
    }
    return imageData;
}

export const updateOnImageTypeChange = (action, photo) => {
    let imageData = {};

    switch ( action.photoType ) {
        case IMAGE_SHADE_MAP:
            imageData = {...imageData, currentMapSettings: photo.shadeMapSettings }
            break;
        case IMAGE_MODIFIER:
            imageData = {...imageData, currentMapSettings: photo.modifierSettings }
            break;
        case IMAGE_TRIPLE_VIEW:
            imageData = {...imageData, currentMapSettings: photo.tripleViewSettings }
            break;
        case IMAGE_DOUBLE_VIEW:
            imageData = {...imageData, currentDoubleMapSettings: photo.doubleViewSettings }
        default:
            break;
    }

    return imageData;
}

export const caseShape = PropTypes.oneOfType([
    PropTypes.bool,

    // TODO expand shape further
    PropTypes.object,
]);

export const photoShape = PropTypes.shape({
    url: PropTypes.string.isRequired,
    filenameOrig: PropTypes.string.isRequired,
    imgOriginal: PropTypes.object,
    imgCorrected: PropTypes.object,
    imgColorMap: PropTypes.object,
    imgViewCorrected: PropTypes.object,
    currentPhotoType: PropTypes.string,
    currentMapSettings: PropTypes.string,
    currentDoubleMapSettings: PropTypes.string
});
export const photosShape = PropTypes.arrayOf(photoShape).isRequired;

export const getLabReassignMessage = data => {
    let message = [];

    if (data.caseAssignedOwnerNotification === 'sent') {
        message.push(texts.SET_CASE_LAB_OLD_EMAIL_SENT);
    }
    if (data.caseAssignedNotification === 'sent' && data.labId !== 0) {
        message.push(texts.SET_CASE_LAB_NEW_EMAIL_SENT);
    }

    if (message.length === 0) {
        message.push(data.labId === 0 ? texts.UNSET_CASE_LAB_SUCCESS : texts.SET_CASE_LAB_SUCCESS);
    }

    return message.join("\n");
};

export const getSpecialistReassignMessage = data => {
    let message = [];

    if (data.caseReassignedOwnerNotification === 'sent') {
        message.push(texts.SET_CASE_SPECIALIST_OLD_EMAIL_SENT);
    }
    //console.log(data.specId, data.specId === 0);
    if (data.caseReassignedNotification === 'sent' && data.specId !== 0) {
        message.push(texts.SET_CASE_SPECIALIST_NEW_EMAIL_SENT);
    }

    if (message.length === 0) {
        message.push(data.specId === 0 ? texts.UNSET_CASE_SPECIALIST_SUCCESS : texts.SET_CASE_SPECIALIST_SUCCESS);
    }

    return message.join("\n");
};
