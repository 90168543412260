import { connect } from 'react-redux';
import PhotoContainer from '../../components/case/PhotoContainer';
import {
    getIsColorCorrectionAllowed,
    getIsRegionActive,
    getPhotoFilters,
    getEditValues,
    getSystemShadeColors,
    isWorkAreaProgress,
    getColorMapSensitivity,
    getModifierSensitivity,
    getPrintData,
    getUndoAvailable,
    getRedoAvailable,
} from "../../selectors/workArea.selector";
import { setPhotoContainerRef, toggleCrop, cropImage, hidePhotoProcessingError } from '../../actions/workArea.actions';
import { addModifierLabel, addShadeMapLabel, addShadeSelected, getRegionValue, processRegion, removeShadeLabel, runColorCorrect, saveCrossHairPosition, saveImageToServer, setHideXMarks, setLeftRightShade, setShowCrossHair, setShowShadeTabs, setShowTextAnnotations, updateShadeLabelsMetadata, updateShadeMapLabels, updateShadeTabCoords, updateShadeTabSettings, updateTextAnnotations, updateGraphicAnnotations, setShowGraphicsAnnotations, undoSavedPhoto, redoSavedPhoto, addShadeTransLabel, updateShadeTransPosition } from '../../actions/case.actions';
import { getCurrentModifier, getCurrentModifierLabels, getCurrentShadeMapLabels, getCurrentPhoto, getCurrentCase, getLeftUsedShadeTab, getRightUsedShadeTab, getShadeTabCoords, getShadeTabSettings, getTextAnnotations, getCrossHairCoords, getGraphicAnnotations, getCurrentShadeTransLabels, getShadeTransPos } from '../../selectors/case.selector';
import { getUserProfile } from '../../selectors/user.selector';

const mapStateToProps = state => ({
    ...getPhotoFilters(state),
    ...getEditValues(state),
    currentCase: getCurrentCase(state),
    currentPhoto: getCurrentPhoto(state),
    shadeGuide: state.case.shadeGuide,
    currentPhotoProcessingStep: state.workArea.currentPhotoProcessingStep,
    systemShadeColors: getSystemShadeColors(state),
    currentShadeReferenceAnalysis: state.workArea.currentShadeReferenceAnalysis,
    isWorkAreaProgress: isWorkAreaProgress(state),
    colorMapSensitivity: getColorMapSensitivity(state),
    modifierSensitivity: getModifierSensitivity(state),
    isRegionActive: getIsRegionActive(state),
    isColorCorrectionAllowed: getIsColorCorrectionAllowed(state),
    currentModifier: getCurrentModifier(state),
    printData: getPrintData(state),
    shadeMapLabels: getCurrentShadeMapLabels(state),
    modifierLabels: getCurrentModifierLabels(state),
    userProfile: getUserProfile(state),
    correctedImageType: state.workArea.correctedImageType,
    leftShadeTab: getLeftUsedShadeTab(state),
    rightShadeTab: getRightUsedShadeTab(state),
    shadeTabCoords: getShadeTabCoords(state),
    shadeTabSettings: getShadeTabSettings(state),
    textAnnotations: getTextAnnotations(state),
    crossHairPos: getCrossHairCoords(state),
    graphicObjects: getGraphicAnnotations(state),
    undoAvailable: getUndoAvailable(state),
    redoAvailable: getRedoAvailable(state),
    shadeTransPos: getShadeTransPos(state)
});

const mapDispatchToProps = dispatch => ({
    hidePhotoProcessingError: () => dispatch(hidePhotoProcessingError()),
    setPhotoContainerRef: (ref) => dispatch(setPhotoContainerRef(ref)),
    toggleCrop: (value) => dispatch(toggleCrop(value)),
    addShadeSelected: (color, refColor, studioColor, coordinate) => dispatch(addShadeSelected(color, refColor, studioColor, coordinate)),
    cropImage: (photoId, caseId, details) => dispatch(cropImage(photoId, caseId, details)),
    runColorCorrect: () => dispatch(runColorCorrect()),
    processRegion: (rect, subrect) => dispatch(processRegion(rect, subrect)),
    getRegionValues: (rect) => dispatch(getRegionValue(rect)),
    addShadeMapLabel: (point) => dispatch(addShadeMapLabel(point)),
    addModifierLabel: (point) => dispatch(addModifierLabel(point)),
    removeShadeLabel: (index) => dispatch(removeShadeLabel(index)),
    updateShadeLabelsMetadata: () => dispatch(updateShadeLabelsMetadata()),
    updateShadeMapLabels: (shLabels, mrLabels) => dispatch(updateShadeMapLabels(shLabels, mrLabels)),
    setHideXMarks: (value) => dispatch(setHideXMarks(value)),
    saveImageToServer: (canvas, photoId, photo_type) => dispatch(saveImageToServer(canvas, photoId, photo_type)),
    setLeftRightShade: (isLeft, shadeName) => dispatch(setLeftRightShade(isLeft, shadeName)),
    updateShadeTabCoords: (photoId, coords) => dispatch(updateShadeTabCoords(photoId, coords)),
    updateShadeTabSettings: (photoId, settings) => dispatch(updateShadeTabSettings(photoId, settings)),
    setShowShadeTabs: (value) => dispatch(setShowShadeTabs(value)),
    updateTextAnnotations: (photoId, value) => dispatch(updateTextAnnotations(photoId, value)),
    updateGraphicAnnotations: (photoId, value) => dispatch(updateGraphicAnnotations(photoId, value)),
    setShowTextAnnotations: (value) => dispatch(setShowTextAnnotations(value)),
    setShowCrossHair: (value) => dispatch(setShowCrossHair(value)),
    saveCrossHairPosition: (payload) => dispatch(saveCrossHairPosition(payload)),
    setShowGraphicsAnnotations: (value) => dispatch(setShowGraphicsAnnotations(value)),
    undoSavedPhoto: (photoId) => dispatch(undoSavedPhoto(photoId)),
    redoSavedPhoto: (photoId) => dispatch(redoSavedPhoto(photoId)),
    addShadeTransLabel: (point) => dispatch(addShadeTransLabel(point)),
    updateShadeTransPosition: (payload) => dispatch(updateShadeTransPosition(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoContainer);
